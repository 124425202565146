import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { Form as FormikForm, Formik, useField } from "formik"
import ComponentButton from "../../components/ComponentButton"
import { Col, Form } from "react-bootstrap"
import ComponentInput from "../../components/ComponentInput"
import ComponentSelect from "../../components/ComponentSelect"
import useMutate from "../../../api/hooks/useMutate"
import { getFields, getInitialValues, getValidationSchema, useIsSubpage } from "../helpers"
import { useLocation, useNavigate } from "react-router-dom"
import ComponentInCreation from "../../components/ComponentInCreation"
import ComponentTextarea from "../../components/ComponentTextarea"
import ComponentPrice from "../../components/ComponentPrice"
import ComponentDate from "../../components/ComponentDate"
import ComponentCheckbox from "../../components/ComponentCheckbox"
import ComponentAddress from "../../components/ComponentAddress"
import ComponentImage from "../../components/ComponentImage"
import useMutateWithFiles from "../../../api/hooks/useMutateWithFiles"
import ComponentTextEditor from "../../components/ComponentTextEditor"
import ComponentFile from "../../components/ComponentFile"
import ComponentRadio from "../../components/ComponentRadio"
import ComponentInfoStrings from "../../components/ComponentInfoStrings"
import ComponentSmartList from "../../components/ComponentSmartList"
import ComponentLayout from "../../components/ComponentLayout"
import ComponentTooltip from "../../components/ComponentTooltip"
import ComponentAnnotation from "../../components/ComponentAnnotation"
import InfoModal from "./src/InfoModal"
import ComponentGooglePlaces from "../../components/ComponentGooglePlaces"
import { useHandleSubmitContext } from "../../../pages/DynamicPage"
import isEqual from "lodash/isEqual"
import { TComponentButton } from "../../components/ComponentButton/_types"
import { TModuleForm, TModuleFormArea, TModuleFormBlock, TModuleFormField } from "./_types";
import ComponentPhoneV2 from "../../components/ComponentPhoneV2"
import { useModalContext } from "../helpers/ModalContext"
import valuesCleaner from "../../helpers/valuesCleaner"
import { downloadFile } from "../../helpers/downloadFile"

//контекст
const FormContext = React.createContext<{ isSuccess: boolean, response?: number, setIsFormPreparing: (state: boolean) => void }>({ isSuccess: false, setIsFormPreparing: () => { } })
export const useFormContext = () => useContext(FormContext)

//Тип поля
export const Component = React.memo<TModuleFormField>((props) => {
    const { article, data_type, field_type, is_disabled, is_visible = true, hook, is_clearable, object_id, request_object } = props
    switch (field_type) {
        case "string":
        case "year":
        case "integer":
        case "float":
        case "email":
        case "password":
            return <ComponentInput
                article={article}
                field_type={field_type}
                is_disabled={Boolean(is_disabled)}
                hook={hook}
                suffix={props.suffix}
            />
        case "textarea":
            return <ComponentTextarea
                article={article}
                is_disabled={Boolean(is_disabled)}
                rows={props.settings?.rows}
            />
        case "list":
        case "link_list" as "list":
        case "color_list" as "list":
            const { joined_field, joined_field_filter, search, settings, list_donor, on_change_submit } = props
            const resolvedObject = settings?.object ?? list_donor?.table
            const resolvedSelect = settings?.select ?? list_donor?.select ?? list_donor?.properties_title
            const resolvedSelectMenu = settings?.select_menu ?? list_donor?.select_menu
            const resolvedIsDuplicate = settings?.is_duplicate ?? settings?.is_duplicate ?? false
            return <ComponentSelect
                article={article}
                data_type={data_type}
                list={props.list ?? []}
                isDisabled={Boolean(is_disabled)}
                isVisible={Boolean(is_visible)}
                isMulti={data_type === "array"}
                hook={hook}
                joined_field={joined_field}
                joined_field_filter={joined_field_filter}
                isDuplicate={resolvedIsDuplicate}
                object={resolvedObject}
                select={resolvedSelect}
                select_menu={resolvedSelectMenu}
                search={search}
                isClearable={(is_clearable === false) ? is_clearable : true}
                onChangeSubmit={on_change_submit}
                withColor={field_type === "color_list" as "list"}

            />
        case "price":
            return <ComponentPrice
                article={article}
                data_type={data_type}
                hook={hook}
                is_disabled={Boolean(is_disabled)}
            />
        case "phone":
            return <ComponentPhoneV2
                article={article}
                hook={hook}
                is_disabled={Boolean(is_disabled)}
                script={props.script} />
        case "date":
        case "time":
        case "datetime":
        case "month":
            return <ComponentDate
                article={article}
                field_type={field_type}
                is_disabled={Boolean(is_disabled)}
                is_clearable={Boolean(is_clearable)}
                hook={hook}
            />
        case "checkbox":
            return <ComponentCheckbox
                article={article}
                is_disabled={Boolean(is_disabled)}
                hook={hook}
            />
        case "dadata_address":
        case "dadata_country":
        case "dadata_region":
        case "dadata_local_area":
        case "dadata_city":
        case "dadata_street":
        case "dadata_passport":
            return <ComponentAddress
                article={article}
                field_type={field_type}
                is_disabled={Boolean(is_disabled)}
            />
        case "google_address":
            return <ComponentGooglePlaces
                article={article}
                is_disabled={Boolean(is_disabled)}
            />
        case "image":
            return <ComponentImage
                article={article}
                allowedFormats={props.settings?.allowed_formats}
                max_size={props.settings?.max_size}
                is_multiply={props.settings?.is_multiply}
                is_editor={props.settings?.is_editor}
                is_disabled={Boolean(is_disabled)} />
        case "editor":
            return <ComponentTextEditor article={article} />
        case "file":
            return <ComponentFile
                article={article}
                allowedFormats={props.settings?.allowed_formats}
                max_size={props.settings?.max_size}
                is_multiply={props.settings?.is_multiply}
                request_object={request_object}
                object_id={object_id} />
        case "radio":
            return <ComponentRadio
                article={article}
                list={props.list}
                is_disabled={Boolean(is_disabled)}
                hook={hook} />
        case "info_strings":
            return <ComponentInfoStrings article={article} />
        case "smart_list":
            const { properties, is_headers_shown } = props.settings
            return <ComponentSmartList article={article} properties={properties} hook={hook} is_headers_shown={is_headers_shown} />
        case "layout":
            const is_edit = Boolean(props.settings?.is_edit)
            return <ComponentLayout article={article} is_edit={is_edit} />
        default:
            return <ComponentInCreation type={field_type} />
    }
})

//Компонент, состоящий из поля определенного типа и лейбла
const ModuleFormField = React.memo<TModuleFormField>((props) => {
    const { annotation, title, is_required, article, buttons, size, object_id, request_object, isFieldDisabled, isFieldVisible, fieldDescription } = props
    const currentButtons = buttons.filter(button => button.settings.field_place === article)
    const resolvedSize = size ? size * 3 : 12
    const resolvedContainerClassName = `moduleForm_field${!isFieldVisible ? " disabled" : ""}${resolvedSize !== 12 ? " marginless" : ""}`

    if (!isFieldVisible) {
        return null
    }
    return <Form.Group className={resolvedContainerClassName} as={Col} md={resolvedSize}>
        {
            title ? <Form.Group className="moduleForm_field_labelContainer" as={Col} md={12}>
                <ComponentTooltip title={title}>
                    <label className={`moduleForm_field_label${is_required ? " required" : ""}`}>{title}</label>
                </ComponentTooltip>
                {annotation ? <ComponentAnnotation annotation={annotation} /> : null}
            </Form.Group> : null
        }
        <Form.Group className="moduleForm_field_container" as={Col} md={12}>
            <div className="moduleForm_field_component">
                <Component
                    {...props}
                    is_disabled={isFieldDisabled}
                    is_visible={isFieldVisible}
                    request_object={request_object}
                    object_id={object_id}
                />
            </div>
            {currentButtons.map(button => <ComponentButton
                className="moduleForm_field_button"
                key={button.type + button.settings.title}
                {...button}
                customHandler={() => { }}
                defaultLabel="icon"
            />)}
        </Form.Group>
        <span className="moduleForm_field_description">{fieldDescription ?? ""}</span>
    </Form.Group>
})
//Блок полей 
const ModuleFormBlock: React.FC<TModuleFormBlock> = props => {
    const { title, fields, buttons, object_id, request_object } = props
    const fieldsArticles = useMemo(() => {
        return fields.map(field => field.article)
    }, [fields])
    const [{ value: visibilityState }] = useField(`fieldsVisibility`)
    const [{ value: descriptionsState }] = useField(`fieldsDescriptions`)
    const [{ value: disabledState }] = useField(`fieldsDisabled`)
    const isBlockVisible = fieldsArticles.some(article => visibilityState[article])
    return <div className={`moduleForm_block card ${!isBlockVisible ? "disabled" : ""}`}>
        {
            title ? <div className="moduleForm_block_title card-header">
                <div className="card-title">
                    <h3>
                        {title}
                    </h3>
                </div>
            </div> : null
        }
        <div className="moduleForm_block_fields card-body">
            {fields.map(field => <ModuleFormField
                key={field.article}
                {...field}
                buttons={buttons}
                request_object={request_object}
                object_id={object_id}
                isFieldDisabled={disabledState[field.article]}
                isFieldVisible={visibilityState[field.article]}
                fieldDescription={descriptionsState[field.article]}
            />)}
        </div>

    </div>
}
const getBlockKey = (block: TModuleFormBlock) => {
    const concatedFieldArticles = block.fields?.reduce((acc, field) => `${acc}-${field.article}`, '')
    return concatedFieldArticles
}
const ModuleFormArea: React.FC<TModuleFormArea> = ({ blocks, size, buttons, object_id, request_object }) => {
    return <Form.Group className="moduleForm_area" as={Col} md={size * 3}>
        {blocks.map(block => <ModuleFormBlock key={getBlockKey(block)}  {...block} buttons={buttons} request_object={request_object} object_id={object_id} />)}
    </Form.Group>
}

const getAreaKey = (area: TModuleFormArea) => {
    const concatedAreaTitles = area.blocks.reduce((acc, block) => {
        const concatedFieldArticles = block.fields?.reduce((acc, field) => `${acc}-${field.article}`, '')
        return `${acc}-${concatedFieldArticles}`
    }, "")
    return `${area.size}${concatedAreaTitles}`
}

type TModuleFormButtons = {
    className: string,
    buttons: Array<TComponentButton>,
    isFormPreparing: boolean
    isSubpage: boolean,
    isFormInsideModal: boolean,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
}

const ModuleFormButtons = React.memo<TModuleFormButtons>(props => {
    const { className, buttons, isSubpage, isFormInsideModal, isFormPreparing, handleSubmit } = props
    const setHandleSubmit = useHandleSubmitContext()
    const resolvedClassName = `componentButton_container ${className}`

    const resolvedButtons = useMemo(() => {
        if (isSubpage && !isFormInsideModal) {
            return buttons.filter(button => button.type !== "submit")
        } else {
            return buttons
        }
    }, [])

    const submitButton = useMemo(() => {
        return buttons.find(button => button.type === "submit")
    }, [])

    useEffect(() => {
        if (isSubpage && !isFormInsideModal && submitButton) {
            setHandleSubmit({ ...submitButton, type: "submit", customHandler: handleSubmit, loading: isFormPreparing, disabled: isFormPreparing })
            return () => setHandleSubmit(prev => isEqual(prev?.customHandler, handleSubmit) ? null : prev)
        }
    }, [isFormPreparing])

    return <Form.Group className={resolvedClassName} as={Col} md={12}>
        {resolvedButtons.map(button => <ComponentButton key={button.type + button.settings.title} {...button} loading={button.type === "submit" && isFormPreparing} disabled={button.type === "submit" && isFormPreparing} customHandler={handleSubmit} />)}
    </Form.Group>
})


const ModuleForm: React.FC<TModuleForm> = ({ components, settings }) => {
    //контекст нужен для передачи дополнительных инит значений (прим.: модалка в расписании)
    const modalContext = useModalContext()
    const initialData = Object.assign({}, modalContext.initialData ?? {}, settings.data ?? {})
    const { object, command, command_type, areas, type, close_after_submit = true, is_disabled } = settings
    const buttons = useMemo(() => Array.isArray(components) ? [] : components.buttons, [components])
    const mainButtons = useMemo(() => buttons.filter(button => !button.settings.field_place), [buttons])
    const fieldButtons = useMemo(() => buttons.filter(button => button.settings.field_place), [buttons])
    const { mutate: classicMutate, isSuccess: isSuccessClassicMutate, isLoading: isClassicMutateLoading, data: responseOnClassicMutate } = useMutate(object, command)
    const { mutate: mutateWithFiles,
        isSuccess: isSuccessMutateWithFiles,
        isLoading: isMutateWithFilesLoading,
        data: responseOnMutateWithFilesData
    } = useMutateWithFiles(object, command)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitedUrl = pathname.slice(1).split("/")
    const id = modalContext.initialData?.id ?? (Number.isInteger(Number(splitedUrl[splitedUrl.length - 1])) ? Number(splitedUrl[splitedUrl.length - 1]) : null)
    const useFormDataRequest = type === "multipart/form-data"
    const isCreating = command_type === "add" || command_type === "custom"
    const mutate = useFormDataRequest ? mutateWithFiles : classicMutate
    const isSuccess = useFormDataRequest ? isSuccessMutateWithFiles : isSuccessClassicMutate
    const isLoading = useFormDataRequest ? isMutateWithFilesLoading : isClassicMutateLoading
    const response = useFormDataRequest ? responseOnMutateWithFilesData : responseOnClassicMutate

    /* функция проверяет, есть ли у формы кнопка submit с переданным url для перехода после отправки формы, и, если есть, осуществляет переход.
    Сам переход выполняется в двух случаях: после успешного запроса или при отсутствии изменении в форме редактирования (отправка запроса игнорируется)  */
    const afterSubmitAction = useCallback((buttons: Array<TComponentButton>, isValuesChanged: boolean) => {
        //смысла нет разбивать на find и проверку наличия, т.к. ts не определит, что это кнопка типа submit. Переделать в дальнейшем
        //@ts-ignore 
        const redirectUrl = buttons.find(button => button.type === "submit")?.settings?.href ?? ""
        if (redirectUrl && !modalContext.setShow) {
            if (redirectUrl === "[return]") {
                const pathsArray = sessionStorage.getItem("paths") ?? JSON.stringify([])
                const resolvedPathsArray = JSON.parse(pathsArray) as Array<string>
                if (resolvedPathsArray.length) {
                    const currentPath = resolvedPathsArray[resolvedPathsArray.length - 1]
                    navigate(currentPath)
                } else {
                    navigate(pathname.slice(1).split("/")[0])
                }
            } else {
                navigate(`/${redirectUrl}`)
            }
        } else if (modalContext.setShow && close_after_submit) {
            modalContext.setShow(false)
        } else if (modalContext.refetchPage && isValuesChanged) {
            modalContext.refetchPage()
        }
    }, [])


    useEffect(() => {
        if (isSuccess) {
            afterSubmitAction(buttons, true)
            if (typeof response?.data === "string" && response?.data.includes("http")) {
                downloadFile(response.data)
            }
            if (modalContext.handleResponse) {
                modalContext.handleResponse(response)
            }
        }
    }, [isSuccess])

    const formConfiguration = useMemo(() => {
        const fields = getFields(areas)
        return {
            initialValues: getInitialValues(fields, isCreating, id && !initialData?.id ? { ...initialData, id } : initialData, is_disabled),
            validationSchema: getValidationSchema(fields)
        }
    }, [areas, initialData])

    const handleSubmit = (formValues: any) => {
        const values = valuesCleaner(formValues)
        if (isLoading || (isSuccess && modalContext.settings?.close_after_submit)) {
            return
        } else {
            if (isCreating) {
                return mutate(values)
            } else {
                let changedValues: any = {}
                for (let key in values) {
                    if ((values[key] !== formConfiguration.initialValues?.[key]) || key === "id") {
                        changedValues[key] = values[key]
                    }
                }
                const valuesKeysAsArray = Object.keys(changedValues)
                const sendRequest = valuesKeysAsArray.length > 1 || (valuesKeysAsArray.length === 1 && !valuesKeysAsArray.includes("id"))
                if (sendRequest) {
                    mutate(changedValues)
                } else {
                    afterSubmitAction(buttons, false)
                }
            }
        }
    }

    const isFormInsideModal = Boolean(modalContext.insideModal)
    const buttonsContainerAdditionalClass = isFormInsideModal ? buttons.filter(button => button.settings.visible !== false).length === 2 ? "between" : "" : "inverse"

    const isSubpage = useIsSubpage()
    const [isFormPreparing, setIsFormPreparing] = React.useState(false)

    const contextValue = useMemo(() => {
        return {
            isSuccess,
            response: response?.data,
            setIsFormPreparing
        }
    }, [isSuccess])

    return <FormContext.Provider value={contextValue}>
        <Formik
            enableReinitialize
            initialValues={formConfiguration.initialValues}
            onSubmit={handleSubmit}
            validationSchema={formConfiguration.validationSchema}
        >
            {({ handleSubmit }) => {
                return <div className="moduleForm">
                    <FormikForm>
                        <div className="moduleForm_container">
                            {areas.map(area => <ModuleFormArea key={getAreaKey(area)} {...area} buttons={fieldButtons} request_object={object} object_id={id} />)}
                        </div>
                        <ModuleFormButtons
                            className={buttonsContainerAdditionalClass}
                            buttons={mainButtons}
                            handleSubmit={handleSubmit}
                            isFormPreparing={isFormPreparing || isLoading}
                            isSubpage={isSubpage}
                            isFormInsideModal={isFormInsideModal}
                        />
                    </FormikForm>
                    <InfoModal />
                </div>
            }}
        </Formik>
    </FormContext.Provider>
}

export default ModuleForm