import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSetupContext } from "../../../helpers/SetupContext"
import { useAuth } from "../../../../modules/auth"
import { IntlShape, useIntl } from "react-intl"
import useRequest from "../../../../api/hooks/useRequest"
import moment from "moment"
import api from "../../../../api"
import { autocompleteDocument } from "../../helpers"

import print from '../../../helpers/print';
import { getErrorToast, getSuccessToast } from "../../../helpers/toasts"
import ComponentTooltip from "../../ComponentTooltip"
import ComponentButton, { getLabel } from ".."
import { Col, Form, Modal } from "react-bootstrap"
import setModalIndex from "../../../helpers/setModalIndex"
import { Formik, Form as FormikForm, useField, useFormikContext } from "formik"
import ComponentSelect from "../../ComponentSelect"
import ComponentTextEditor from "../../ComponentTextEditor"
import { useFormContext } from "../../../modules/ModuleForm"
import { TComponentButtonPrint } from "../_types"
import { useModalContext } from "../../../modules/helpers/ModalContext"


const selectedDocumentArticle = "selectedDocument"
const documentTitleArticle = "selectedDocumentTitle"
const documentBodyArticle = "documentContent"

type Tdocument = { title: string, article: string, object?: string, structure: Array<{ block_type: string, settings: { document_body: string } }> }

type TlocalDocument = { [documentTitleArticle]: string, [documentBodyArticle]: string, [selectedDocumentArticle]: number | null }

type TDocumentBodyListner = {
    isScriptExecuted: boolean,
    storageKey: string,
    intl: IntlShape,
    setIsScriptExecuted: React.Dispatch<React.SetStateAction<boolean>>,
}


const DocumentBodyListener: React.FC<TDocumentBodyListner> = props => {
    const { isScriptExecuted, storageKey, intl, setIsScriptExecuted } = props
    const [{ value: id }] = useField(selectedDocumentArticle)
    const [{ value: title }] = useField(documentTitleArticle)
    const [{ value }] = useField(documentBodyArticle)
    const { setValues } = useFormikContext()
    const [isNeedToSaveFile, setIsNeedToSaveFile] = useState(false)
    const [localSavedFile, setLocalSavedFile] = useState<null | TlocalDocument>(null)

    /*
    --- Прослушивание изменений в документе на сброс состояния исполнения скрипта с прошлой вариацией документа  
    */

    useEffect(() => {
        setIsScriptExecuted(false)
    }, [value])

    /*
    --- Проверка на наличие локального несохраненного документа и триггер на запись несохраненного документа в локальное хранилище
    */

    useEffect(() => {
        if (storageKey) {
            const localSavedFile = localStorage.getItem(storageKey)
            if (localSavedFile) {
                const parsedFile = JSON.parse(localSavedFile) as TlocalDocument
                setLocalSavedFile(parsedFile)
            }

        }
    }, [])

    useEffect(() => {
        if (isNeedToSaveFile) {
            if (isScriptExecuted) {
                setIsNeedToSaveFile(false)
            } else {
                localStorage.setItem(storageKey, JSON.stringify({ [documentTitleArticle]: title, [documentBodyArticle]: value, [selectedDocumentArticle]: id }))
            }
           
        }
    }, [isNeedToSaveFile])

    /*
    --- Прослушивание разрыва соединения и перезагрузки/закрытия вкладки 
    */

    useEffect(() => {
        const handle = () => setIsNeedToSaveFile(true)
        window.addEventListener("offline", handle)
        return () => window.removeEventListener("offline", handle)
    }, [])

    useEffect(() => {
        const handle = () => setIsNeedToSaveFile(true)
        window.addEventListener("beforeunload", handle)
        return () => window.removeEventListener("beforeunload", handle)
    }, [])

    const handleCleanLocalFile = () => {
        localStorage.removeItem(storageKey)
        setLocalSavedFile(null)
    }

    const handleSetValues = () => {
        setValues(localSavedFile)
        handleCleanLocalFile()
    }

    return <Modal
        size="lg"
        centered
        show={Boolean(localSavedFile)}
        onEntering={setModalIndex}
        onHide={handleCleanLocalFile}
        enforceFocus={false}
    >
        <Modal.Header>
            <Modal.Title>
                Внимание
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            У вас есть несохраненный документ. Хотите открыть его?
        </Modal.Body>
        <Modal.Footer>
            <ComponentButton
                type="custom"
                settings={{ title: intl.formatMessage({ id: "BUTTON.CANCEL" }), background: "secondary", icon: "" }}
                customHandler={handleCleanLocalFile}
            />
            <ComponentButton
                type="custom"
                settings={{ title: intl.formatMessage({ id: "BUTTON.OPEN" }), background: "dark", icon: "" }}
                customHandler={handleSetValues}

            />
        </Modal.Footer>
    </Modal>
}

const ComponentButtonPrint: React.FC<TComponentButtonPrint> = ({ settings, defaultLabel = "title", className = "" }) => {
    const ref = useRef<HTMLButtonElement | null>(null)
    const { context: applicationContext } = useSetupContext()
    const { currentUser } = useAuth()
    const intl = useIntl()
    const { data, context, visible = true, afterSubmit = false } = settings
    const storageKey = JSON.stringify(data)
    const { is_edit, row_id, scheme_name, document_article, script } = data
    const resolvedContext = Object.assign({ block: "print" }, context ?? {})
    const { data: documents } = useRequest<Array<Tdocument>>("documents", "get", { context: resolvedContext })
    const [showEditModal, setShowEditModal] = useState(false)
    const [isMinimize, setIsMinimize] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentDocument, setCurrentDocument] = useState<{ title: string, body: string } | null>(null)
    const isIconBased = defaultLabel === "icon"
    const { isSuccess, response } = useFormContext()

    const [isScriptLoading, setIsScriptLoading] = useState(false)
    const [isScriptExecuted, setIsScriptExecuted] = useState(false)


    //проверка на нахождение внутри другого модального окна
    const outterModalContext = useModalContext()
    const insideOutterModal = Boolean(outterModalContext.insideModal)

    const handleScriptAction = useCallback(async (title: string, body: string) => {
        localStorage.removeItem(storageKey)
        if (script) {
            try {
                setIsScriptLoading(true)
                const resolvedTitle = `${title} (${moment().format("DD.MM.YY г.")})`
                const requestData = Object.assign({}, { title: resolvedTitle, body }, script.properties ?? {})
                await api(script.object, script.command, requestData)
                getSuccessToast()
                setIsScriptExecuted(true)
            } catch (error) {
                //@ts-ignore
                getErrorToast(error.message)
            } finally {
                setIsScriptLoading(false)
            }
        }
    }, [script])

    useEffect(() => {
        if (isSuccess && afterSubmit) {
            ref.current?.click()
        }
    }, [isSuccess])


    const handleMinimize = () => {
        setIsMinimize(true)
        if (!insideOutterModal) {
            document.body.setAttribute("style", "padding-right: 4px")
        }
    }

    const handleClick = async () => {
        if (isMinimize) {
            setIsMinimize(false)
            if (!insideOutterModal) {
                document.body.setAttribute("style", "overflow: hidden; padding-right: 4px")
            }
        } else {
            const resolvedRowId = afterSubmit && typeof response === "number" ? response : row_id
            const documentsResponse = document_article ? await api<Array<Tdocument>>("documents", "get", { article: document_article }) : null
            const documentFromResponse = documentsResponse ? documentsResponse.data[0] : null
            const document = documentFromResponse ? await autocompleteDocument(documentFromResponse, resolvedRowId, scheme_name, currentUser, applicationContext) : null
            if (is_edit) {
                setShowEditModal(true)
                if (document) {
                    return setCurrentDocument({ title: documentFromResponse?.title ?? "", body: document ?? "" })
                }
            } else {
                if (documentFromResponse && document) {
                    print(document)
                    handleScriptAction(documentFromResponse.title, document)
                } else {
                    getErrorToast(intl.formatMessage({ id: "BUTTON_PRINT.DOCUMENT_ERROR" }))
                }
            }
        }
    }

    const handleSelect = async (label: { label: string, value: string } | null, setFieldValue: (article: string, value: any) => void) => {
        setIsLoading(true)
        if (label && label.value) {
            const documentsResponse = await api<Array<Tdocument>>("documents", "get", { article: label.value })
            const documentFromResponse = documentsResponse ? documentsResponse.data[0] : null
            const document = documentFromResponse ? await autocompleteDocument(documentFromResponse, row_id, scheme_name, currentUser, applicationContext) : null
            setFieldValue(documentBodyArticle, document ?? "")
            setFieldValue(documentTitleArticle, documentFromResponse?.title ?? "")
        }
        setFieldValue(selectedDocumentArticle, label?.value ?? null)
        setIsLoading(false)
    }

    const handleSubmit = (values: { selectedDocumentTitle: string, documentContent: string }) => {
        print(values.documentContent)
        if (!isScriptExecuted) {
            handleScriptAction(values.selectedDocumentTitle, values.documentContent)
        }
    }

    const initialValues = useMemo(() => ({
        [selectedDocumentArticle]: null,
        [documentTitleArticle]: currentDocument?.title ?? "",
        [documentBodyArticle]: currentDocument?.body ?? ""
    }), [currentDocument])

    const documentsList = useMemo(() => documents && Array.isArray(documents) ? documents.map(document => ({ title: document.title, value: document.article })) : [], [documents])

    const resolvedClassName = `componentButton${isIconBased ? " icon_based" : ""} ${className} ${settings.background} ${isMinimize ? " minimized" : ""} ${visible ? "" : " hidden"}`

    return <>
        <ComponentTooltip title={settings.title ?? ""} show={isIconBased ? undefined : false}>
            <button
                ref={ref}
                className={resolvedClassName}
                type="button"
                onClick={handleClick}>
                {getLabel(defaultLabel, settings)}
            </button>

        </ComponentTooltip>

        <Modal
            size="xl"
            className={isMinimize ? " hidden" : ""}
            dialogClassName="componentButton_modal"
            backdropClassName={isMinimize ? "hidden" : ""}
            show={showEditModal}
            onEntering={setModalIndex}
            onHide={handleMinimize}
            enforceFocus={false}
        >
            <Modal.Header>
                <Modal.Title>
                    {intl.formatMessage({ id: "BUTTON_PRINT.MODAL_TITLE" })}
                </Modal.Title>
                <button type="button" className="componentButton_modalButton modalMinimize" onClick={handleMinimize}>-</button>
                <button type="button" className="componentButton_modalButton modalClose" onClick={() => setShowEditModal(false)}>x</button>
            </Modal.Header>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {({ setFieldValue, handleSubmit, values }) => <FormikForm>
                    <DocumentBodyListener
                        storageKey={storageKey}
                        intl={intl}
                        isScriptExecuted={isScriptExecuted}
                        setIsScriptExecuted={setIsScriptExecuted}
                    />
                    <Modal.Body className="d-flex flex-wrap align-items-center justify-content-end">
                        <div className="componentButton_modalTooltip">
                            {!currentDocument ?
                                <ComponentSelect
                                    article={selectedDocumentArticle}
                                    data_type="string"
                                    list={documentsList}
                                    placeholder={intl.formatMessage({ id: "BUTTON_PRINT.SELECT_PLACEHOLDER" })}
                                    customHandler={(value) => handleSelect(value, setFieldValue)}
                                    isLoading={isLoading}
                                />
                                : null}
                            <ComponentButton
                                type="custom"
                                settings={{ title: intl.formatMessage({ id: "BUTTON.SAVE" }), background: "dark", icon: "files" }}
                                customHandler={() => handleScriptAction(values.selectedDocumentTitle, values.documentContent)}
                                defaultLabel="icon"
                                loading={isScriptLoading}
                                disabled={!values.documentContent}
                            />
                            <ComponentButton
                                type="custom"
                                settings={{ title: intl.formatMessage({ id: "BUTTON.PRINT" }), background: "dark", icon: "print" }}
                                customHandler={handleSubmit}
                                defaultLabel="icon"
                                disabled={!values.documentContent}
                            />
                        </div>
                        {values.documentContent ? <Form.Group as={Col} md={12}>
                            <ComponentTextEditor article={documentBodyArticle} />
                        </Form.Group> : null}
                    </Modal.Body>
                </FormikForm>}
            </Formik>
        </Modal>
    </>
}

export default ComponentButtonPrint