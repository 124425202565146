import { useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import api from ".."
import { useAuth } from "../../modules/auth"
import { TApiResponse } from "../../types/api"
import { getErrorToast } from "../../constructor/helpers/toasts"

type TProps = {
    functions: Array<{active: boolean, update: () => void}>,
    table_name?: string,
    additionals?: {[key: string]: any},
    refetchInterval?: number,
    enabled?: boolean
}

const useUpdate = (props: TProps) => {

    const {functions, table_name, additionals = {}, refetchInterval = 5000, enabled = true} = props

    const { currentUser } = useAuth()
    const userId = Number(currentUser?.id)
    const request = useMemo(() => Object.assign({ table_name, user_id: userId }, additionals), [additionals])
    const fetchKey = ["events", request]

    const fetchFunction = () => api<string | number>("events", "get-last-id", request)
    const isEnabled = Boolean(userId) && Boolean(table_name) && enabled
    const hookConfiguration = {
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
        enabled: isEnabled,
        select: (data: TApiResponse<string | number>) => data.data,
        onError: (error: any) => getErrorToast(error.message),

    }
    const { isLoading, error, data: updateLog } = useQuery(fetchKey, fetchFunction, hookConfiguration)

    useEffect(() => {
        if (updateLog) {
            functions.forEach(fc => {
                if (fc.active) {
                    fc.update()
                }
            })
        }
    }, [updateLog])
}

export default useUpdate